import { createRouter, createWebHistory } from "vue-router";
import modules from "@/router/routes-registration";
import { APPLICATIONS } from "@/services/consts/application";

import * as middlewares from "@/router/middlewares";

const routes = [
  {
    path: "/",
    name: "r_home",
    meta: {
      middlewares: {
        auth: null,
        document: { applicationId: APPLICATIONS.APP_CLEBEX_HUB.id },
      },
    },
    component: () => import("../views/Welcome.vue"),
    children: [
      {
        path: "home",
        name: "r_home-alt",
        meta: {
          middlewares: {
            auth: null,
            document: { applicationId: APPLICATIONS.APP_CLEBEX_HUB.id },
          },
        },
        component: () => import("../views/Welcome.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "r_login",
    meta: {
      middlewares: {},
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/tmp-code",
    name: "r_tmp-code",
    meta: { middlewares: {} },
    component: () => import("../views/TmpCodeLogin.vue"),
  },
  {
    path: "/forgot-password",
    name: "r_forgot-password",
    meta: {},
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "r_reset-password",
    meta: {},
    component: () => import("../views/ResetPassword"),
  },
  {
    path: "/permissions",
    name: "r_permissions",
    meta: {
      middlewares: { auth: null },
    },
    component: () => import("../views/Permissions.vue"),
  },
  {
    path: "/404",
    name: "r_error",
    meta: {
      middlewares: { auth: null },
    },
    component: () => import("../views/ErrorPage"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "r_not-found",
    meta: {
      middlewares: {},
    },
    redirect: { name: "r_error" },
  },
  ...modules,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // Always load main middleware
  let result = Promise.resolve(middlewares.main({ to }));
  Object.keys(to.meta.middlewares || {}).forEach((current) => {
    result = result.then((res) => {
      // If response is type of object we immediately skip other middlewers
      if (typeof res === "object") {
        return res;
      }
      let data = {};
      if (typeof to.meta.middlewares[current] !== undefined) {
        data = to.meta.middlewares[current];
      }
      return middlewares[current]({
        to,
        from,
        next,
        data: data,
      });
    });
  });
  return result.then((res) => {
    /**
     * Finally call router next func with result from previous middleware
     */
    return next(res);
  });
});

export default router;
