export const preferenceMenuModules = [
  {
    pathName: "r_5dmaps",
  },
  {
    pathName: "r_clean",
  },
  {
    pathName: "r_count",
  },
  {
    pathName: "r_occupancy",
  },
  {
    pathName: "r_my-visits",
  },
  {
    pathName: "r_one-click",
  },
  {
    pathName: "r_plan",
  },
  {
    pathName: "r_calendar",
  },
  {
    pathName: "r_pass",
  },
  {
    pathName: "r_realtime",
  },
  {
    pathName: "r_visitor-management",
  },
  {
    pathName: "r_my-visitors",
  },
  {
    pathName: "r_cockpit",
  },
  {
    pathName: "r_bookings",
  },
  {
    pathName: "r_services",
  },
  {
    pathName: "r_company-documents-accept-documents",
  },
];
